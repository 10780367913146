/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
	background-color: $color-primary;
	padding: 32px 0;

	a {
		color: #fff;
	}

	&__col {
		gap: 48px;
	}

	&__info {
		@include bp-down(medium) {
			text-align: center;
		}

		img {
			display: block;
			margin-bottom: 24px;
			width: 100%;
			height: auto;
		}
	}

	&__socials {
		margin-bottom: 24px;
		gap: 10px;

		a {
			transition: $global-transition;

			&:hover {
				filter: invert(1);
			}
		}
	}

	&__copy {
		text-align: right;

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}

	&__info,
	&__copy {
		max-width: 202px;
		width: 100%;
		font-size: em(14);
		line-height: 1.4;
	}
}
