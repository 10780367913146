.b-stats {
	padding: 40px 0;

	&__heading {
		// font-size: em(32);
		// line-height: normal;
		color: $color-secondary;
		margin: 0 0 12px 0;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin: 0;
			text-align: right;
		}

		&--center {
			@include media-breakpoint-up(lg) {
				text-align: center;
			}
		}
	}

	&__list {
		background-color: $color-fill-grey;
		padding: 1px;
		margin-top: 24px;
		gap: 1px;
		width: 100%;

		@include media-breakpoint-up(lg) {
			margin-top: 53px;
		}
	}

	&__all {
		margin-top: 36px;

		@include media-breakpoint-up(lg) {
			margin-top: 55px;
		}
	}

	&__item {
		width: 100%;
		background-color: $color-white;
		color: $color-secondary;
		height: 100%;
		padding: 24px 12px;
		font-size: em(16);
		line-height: 1.5;

		@include media-breakpoint-up(lg) {
			padding: 19px 12px;
		}

		span {
			line-height: 1.25;
			font-size: em(56);
			margin: 0 12px;
			display: inline-block;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: center;

		&--twice {
			margin-top: 30px;
		}
	}

	&__input {
		margin-left: 20px;
	}

	.c-form__selected {
		width: 100%;

		@include media-breakpoint-up(lg) {
			min-width: 375px;
		}
	}
}
