/* stylelint-disable selector-no-qualifying-type */

main {
	line-height: 1.7;
	color: $color-secondary;

	article:not(.b-posts__preview) img {
		margin: 5% 2%;
	}
	// a span,
	// section:not(.b-slider) a{
	// 	color: #00b1e7 !important;
	// 	text-decoration: none;
	// }
	// a span:hover{
	//  text-decoration: underline;
	// }
}

ul {
	list-style: none;
}

article ul li::before {
	content: '•';
	color: #ed2369 !important;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

input[name='queueId']:checked {
	accent-color: $color-fill-accent;
}

// stylelint-disable-next-line selector-max-id
#my-react-app > div {
	z-index: 2;
}

.has-primary-color {
	color: $color-fill-accent;
}

.has-secondary-color {
	color: #00b1e7;
}

/* stylelint-enable selector-no-qualifying-type */
